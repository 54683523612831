import { useEffect, useState } from "react"
import ParkSearchForm from "@/components/custom/ParkSearchForm"
import MarketingSections from "@/components/custom/MarketingSections"
import { useSearchFlow } from "@/hooks/useSearchFlow"
import Loading from "@/components/custom/Loading"
import TypographyH1 from "@/components/ui/typography/h1"
import { TypographyH2 } from "@/components/ui/typography/h2"
import { TypographyP } from "@/components/ui/typography/p"
import { useLabels } from "@/context/LabelContext"
import { MetaHead } from "@/components/custom/Meta/Head"
import { getCollections, search } from "@/api/get"
import { PromoPopup } from "@/pages/PromoPopup/PromoPopup"
import { useEmailSignup } from "@/hooks/useEmailSignup"
import { emailSignup } from "@/api/post"

const Home = () => {
  const [facilityCollections, setFacilityCollections] = useState([])
  const [quickBookOptions, setQuickBookOptions] = useState([])
  const [loadingQuickBookOptions, setLoadingQuickBookOptions] = useState(true)
  const { getLabel, labels } = useLabels()
  const { facilities, facilitiesLoading, handleSearchQuery, searchQuery } = useSearchFlow()

  const fetchCollections = async () => {
    try {
      const collections = await getCollections()
      return setFacilityCollections(collections)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchQuickBookOptions = async () => {
    try {
      const quickBookOptions = await search()
      setLoadingQuickBookOptions(false)
      return setQuickBookOptions(quickBookOptions.slice(0,6))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchQuickBookOptions()
    fetchCollections()
  }, [])

  const { isOpen, handleClose, handleSubmit: handlePromoSubmit, email, setEmail } = useEmailSignup({ storageKey: "PromoPopup" })


  if (facilitiesLoading) {
    return <Loading />
  }

  return (
    <div className="w-full relative">
      <MetaHead />
      {isOpen && <PromoPopup onClose={handleClose} onSubmit={handlePromoSubmit} email={email} setEmail={setEmail} />}
      <section className="relative h-fit py-48 md:py-56 px-3 flex items-center justify-center bg-center min-[650px]:bg-heroNew max-[650px]:bg-heroNew bg-cover bg-no-repeat xl:bg-top">
        <div className="top-0 bottom-0 left-0 right-0 bg-gray-800 opacity-30 absolute" />
        <div className="w-full max-w-2xl z-10 p-9">
          <TypographyH1 className="text-white text-pretty lg:mb-2 text-center [text-shadow:_1px_1px_2px_#111729]">
            Reserve Your Parking Spot in Vail & Beaver Creek
          </TypographyH1>
          <TypographyP className="text-xl text-white [&:not(:first-child)]:mt-0 text-pretty mb-8 text-center [text-shadow:_1px_1px_2px_#111729]">
            {/* {getLabel(
              "landing.hero_subtitle",
              "Avoid the hassle and reserve your parking spot in seconds with Spotsurfer's real-time reservation system."
            )} */}
            Skip the parking hassle! Stress-free parking is just a tap away.
          </TypographyP>

          <div className="mt-3 flex flex-col items-center w-full">
            <ParkSearchForm
              searchQuery={searchQuery}
              handleSearchQuery={handleSearchQuery}
              facilities={facilities}
              facilityCollections={facilityCollections}
              showTitle={false}
              labelClassName="text-white"
              isHomePage={true}
            />
          </div>
        </div>
      </section>
      <MarketingSections locations={quickBookOptions} isLoading={loadingQuickBookOptions} handlePromoSubmit={handlePromoSubmit} email={email} setEmail={setEmail}/>
    </div>
  )
}

export default Home
